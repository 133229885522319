<template>
  <div class="wrapper">
    <base-button
      icon type="primary"
      class="flotingBtn"
      :class="[isActive ? 'tagBtn2' : 'tagBtn']"
      @click="isActive = !isActive"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-right"></i></span>
    </base-button>
    <base-button
      icon type="primary"
      class="flotingBtn2"
      @click="toggleClass"
      :class="[isActive ? 'tagBtn' : 'tagBtn2']"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-left"></i></span>
    </base-button>
    <Sidebar :class="{tagBtn: isActive}" />
    <div class="main-content" :class="{DashboardBody: isActive}">
        <SampleNavbar/>
        <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
            <b-breadcrumb>
                <b-breadcrumb-item href="#" active>Testimonials</b-breadcrumb-item>
                <!-- <b-breadcrumb-item href="#" active>Blog Category</b-breadcrumb-item> -->
            </b-breadcrumb>
        </base-header>
        <b-container fluid class="mt--7">
            <b-row>
                <b-col md="12">
                    <b-card type="default" header-classes="bg-transparent">
                        <b-row  class="mb-2">
                            <b-col md="8"></b-col>
                            <b-col md="2"><base-button block type="primary" @click="$bvModal.show('bv-modal-testimonials')" >Add Testimonial</base-button></b-col>
                            <b-col md="2"><base-button block type="primary">Export to CSV</base-button></b-col>
                        </b-row>
                        <vue-good-table
                        :columns="columns"
                        :rows="rows"
                        :line-numbers="true"
                        :search-options="{
                        enabled: true,
                        placeholder: 'Search this table',
                        }"
                        :pagination-options="{
                        enabled: true,
                        mode: 'records'
                        }"
                        >
                        <template slot="table-row" slot-scope="props" style="w">
                            <span v-if="props.column.field == 'before'">
                              <img :src="props.row.testimonial_image" width="100%" />
                            </span>
                            <span v-if="props.column.field == 'after'">
                              <base-button
                              icon type="primary"
                              size="sm"
                              @click="testimonialEdit(props.row.testimonial_id)"
                              >
                              <span class="btn-inner--icon"><i class="far fa-edit"></i></span>
                              </base-button>
                              <base-button
                              icon type="danger"
                              size="sm"
                              @click="checkedClick(props.row.testimonial_id)"
                              >
                              <span class="btn-inner--icon">
                                <i class="ni ni-check-bold" v-if="props.row.testimonial_active"></i>
                                <i class="ni ni-fat-remove" v-else></i>
                              </span>
                              </base-button>
                            </span>
                            <span v-else>
                              {{props.formattedRow[props.column.field]}}
                            </span>
                        </template>
                        </vue-good-table>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
        <b-modal id="bv-modal-testimonials" hide-footer>
          <template #modal-title>
            <h2 style="margin:0px">Add Testimonials</h2>
          </template>
          <div class="d-block text-center">
            <b-form style="text-align: left">
                <b-row>
                    <b-col md="12">
                      <label>Testimonial Name *</label>
                      <b-input v-model="testimonial.name" type="text" placeholder="Add Testimonial Name"></b-input>
                    </b-col>
                    <b-col md="12" class="mt-4">
                      <label>Testimonial Image</label>
                      <b-input v-model="testimonial.image" type="text" placeholder="Add Testimonial Link" />
                    </b-col>
                    <b-col md="12" class="mt-4">
                      <label>Testimonial Content *</label>
                      <b-textarea
                      id="textarea"
                      v-model="testimonial.content"
                      placeholder="Add Testimonial Content"
                      rows="3"
                      max-rows="6"
                      ></b-textarea>
                    </b-col>
                    <b-col md="12" class="mt-4"><base-button block type="primary" @click="addTestimonial">Add Testimonial</base-button></b-col>
                </b-row>
            </b-form>
          </div>
        </b-modal>
        <Footer/>
    </div>
  </div>
</template>
<script>
import Sidebar from './../../Starter/Sidebar'
import SampleNavbar from './../../Starter/SampleNavbar'
import Footer from './../../Starter/SampleFooter'
import { API_URL } from '../../../router/api';
export default {
  name: 'testimonials',
  components: {
    Sidebar,
    SampleNavbar,
    Footer
  },
  data () {
    return {
      isActive: false,
      checked: true,
      columns: [
        {
          label: 'Testimonial Image',
          field: 'before',
          width: '200px',
        },
        {
          label: 'Testimonial Name',
          field: 'testimonial_name'
        },
        {
          label: 'Testimonial Content',
          field: 'testimonial_content'
        },
        {
          label: 'Actions',
          field: 'after'
        }
      ],
      testimonial: {
        id: null,
        name: null,
        image: null,
        content: null
      }
    }
  },
  computed: {
    rows () {
      return this.$store.state.testimonialList == null ? [] : this.$store.state.testimonialList
    }
  },
  methods: {
    toggleClass: function (event) {
      this.isActive = !this.isActive
    },
    addTestimonial() {
      const self = this;
      console.log(this.testimonial);
      const api = {
      callback() {
          self.axios.post(API_URL+'admin_testimonials',self.testimonial)
          .then((response) => {
            let obj = response.data
            obj.status === true ? self.$store.dispatch('alertSuccess', obj.message) : self.$store.dispatch('alertError', obj.message)
            self.$store.dispatch('getTestimonialShow')
            self.testimonial = {};
            self.$bvModal.hide('bv-modal-testimonials')
          })
        }
      }
      this.testimonial.name == null || this.testimonial.name == '' ? self.$store.dispatch('alertError', "Select Testimonial Name")
      : this.testimonial.image == null || this.testimonial.image == '' ? self.$store.dispatch('alertError', "Testimonial Image Url Empty")
      : this.testimonial.content == null || this.testimonial.content == '' ? self.$store.dispatch('alertError', "Testimonial Content Empty")
      : api.callback()
    },
    checkedClick(id) {
      this.rows.map(row => {
        if(row.testimonial_id === id) {
          row.testimonial_active = !row.testimonial_active; 
          let active = row.testimonial_active;
          this.axios.post(API_URL+'admin_testimonials_active',{id: id, active: active})
          .then((res) => {
            let status = res.data.status;
            let message = res.data.message;
            if(status == true) {
              this.$store.dispatch('getTestimonialShow');
              this.$store.dispatch('alertSuccess', message)
            }else {
              this.$store.dispatch('alertSuccess', message)
            }
          })
        }
        return row
      })
    },
    testimonialEdit(id) {
      let testimonialShow = this.$store.state.testimonialList == null ? []
      : this.$store.state.testimonialList.find((data) => {
        return data.testimonial_id === id
      })
      this.testimonial = {
        id : testimonialShow.testimonial_id,
        name: testimonialShow.testimonial_name,
        image: testimonialShow.testimonial_image,
        content: testimonialShow.testimonial_content
      }
      this.$bvModal.show('bv-modal-testimonials')
    }
  }
}
</script>
<style lang="scss">
.DashboardBody {
  margin-left: 0px !important;
}
.tagBtn {
  display: none !important;
}
.tagBtn2 {
  display: block !important;
}
// #bv-modal-testimonials{
//   @media (min-width: 576px){
//     .modal-dialog {
//         max-width: 900px !important;
//     }
//   }
// }
</style>
